<script lang="ts" setup>
const { t } = useI18n()

const partnerList = ref([
  {
    id: 0,
    title: 'ArtBat',
    imageSrc: '/images/home/partners/artbat.webp',
  },
  {
    id: 1,
    title: 'Hub Culture',
    imageSrc: '/images/home/partners/hubculture.webp',
  },
  {
    id: 2,
    title: 'Mayan Warrior',
    imageSrc: '/images/home/partners/mw.webp',
  },
  {
    id: 3,
    title: 'Reface',
    imageSrc: '/images/home/partners/reface.webp',
  },
  {
    id: 4,
    title: 'Bitfury',
    imageSrc: '/images/home/partners/bitfury.webp',
  },
  {
    id: 5,
    title: 'Bridge',
    imageSrc: '/images/home/partners/bridge.webp',
  },
  {
    id: 6,
    title: 'Go X',
    imageSrc: '/images/home/partners/go-x.webp',
  },
  {
    id: 7,
    title: 'MarkStroke',
    imageSrc: '/images/home/partners/mark-stroke.webp',
  },
  {
    id: 8,
    title: 'Eternalaw',
    imageSrc: '/images/home/partners/eternalaw.webp',
  },
  {
    id: 9,
    title: 'SingularityDao',
    imageSrc: '/images/home/partners/singularitydao.png',
  },
  {
    id: 10,
    title: 'CASCADE.VC',
  },
  {
    id: 11,
    title: 'Decentraland',
  },
])

const partnerSizeClasses: { [key: number]: string } = {
  0: 'min-w-56 max-w-56',
  1: 'min-w-63.5 max-w-63.5',
  2: 'min-w-37.5 max-w-37.5',
  3: 'min-w-43 max-w-43',
  4: 'min-w-47 max-w-47',
  5: 'min-w-32 max-w-32',
  6: 'min-w-35 max-w-35',
  7: 'min-w-10 max-w-10',
  8: 'min-w-54.5 max-w-54.5',
  9: 'min-w-49.5 max-w-49.5',
  10: 'min-w-38.5 max-w-38.5',
  11: 'min-w-52.5 max-w-52.5',
}
</script>

<template>
  <div>
    <p class="text-center text-2xl md:text-[2rem] px-5 lg:px-0">
      {{ t('funded-by-the-creators-of-brands-you-know-and-love') }}
    </p>

    <div class="carousel max-w-360 overflow-hidden flex mx-auto relative mt-14">
      <div
        v-for="(i, index) in 2"
        class="w-30 h-full absolute top-0 m-auto bg-gradient-to-r z-9"
        :class="{
          'left-0 from-[#151515] to-transparent': index === 0,
          'right-0 to-[#151515] from-transparent': index === 1,
        }"
      />
      <div
        v-for="i in 2"
        class="flex flex-row items-center gap-16.5 carousel-track pr-16.5"
      >
        <div
          v-for="item in partnerList"
          :key="item.id"
          :class="partnerSizeClasses[item.id]"
        >
          <div v-if="item.title === 'Decentraland'" class="flex flex-row">
            <i class="i-belong:decentraland w-9 h-9" />
            <p class="font-500 text-[22px] text-white tracking-wider ml-2">
              Decentraland
            </p>
          </div>

          <div
            v-if="item.title === 'CASCADE.VC'"
            class="font-500 text-[22px] text-white tracking-wider"
          >
            CASCADE.VC
          </div>

          <div v-else class="flex items-center justify-center">
            <NuxtImg loading="lazy" alt="" :src="item.imageSrc" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
